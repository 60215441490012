import React, { useState } from 'react'
import Draggable from 'react-draggable'
import { Header } from './viewer/_shared/ModalHeader'
import './viewer/_shared/viewer.css';
import 'react-h5-audio-player/lib/styles.css';
import { useAuth } from 'oidc-react';

const Settings = ({ close, deleteMessages, setBadgeCount, resetState }) => {
   
    const auth = useAuth();

    const resetGame = () => {
        deleteMessages();
        setBadgeCount(0);
        resetState();
        close();
    }

    //settings
    const [resetConfirmationOpen, setResetConfirmationOpen] = useState(false);
    const closeResetConfirmation = () => {
        setResetConfirmationOpen(false)
    }
    const openResetConfirmation = () => {
        setResetConfirmationOpen(true);
    }

    const [logoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
    const closeLogoutConfirmation = () => {
        setLogoutConfirmationOpen(false)
    }
    const openLogoutConfirmation = () => {
        setLogoutConfirmationOpen(true);
    }

    if (logoutConfirmationOpen)
        return (
            <Draggable handle=".hak-modal-header-label-secondlevel" bounds={"#draggable-bounds"}>
                <div className={"hak-modal-settings"}>
                    <Header icon={{ label: "Camp Calamity Settings Menu" }} close={close} secondLevel={true} />
                    <div className={"center"} style={{ color: "darkred", padding: "10px" }}>Are you sure you want to log out of Camp Calamity?</div>
                    <div className={"hak-settings-inside-horizontal"}>
                        <div style={{ padding: "10px" }}>
                            <div onClick={auth.signOutRedirect} className={"btn btn-dark"}>
                                Yes, Exit Game
                    </div>
                        </div>
                        <div onClick={() => closeLogoutConfirmation()} className={"btn btn-dark"} >
                            Cancel
                    </div>
                    </div>
                </div>
            </Draggable>)
    else if (resetConfirmationOpen)
        return (
            <Draggable handle=".hak-modal-header-label-secondlevel" bounds={"#draggable-bounds"}>
                <div className={"hak-modal-settings"}>
                    <Header icon={{ label: "Camp Calamity Settings Menu" }} close={close} secondLevel={true} />
                    <div className={"center"} style={{ color: "darkred", padding: "10px" }}>Are you sure you want to restart your investigation? This will reset your progress on Camp Calamity completely.</div>
                    <div className={"hak-settings-inside-horizontal"}>
                        <div style={{ padding: "10px" }}>
                            <div onClick={() => resetGame()} className={"btn btn-dark"}>
                                Yes, Restart Game
                    </div>
                        </div>
                        <div onClick={() => closeResetConfirmation()} className={"btn btn-dark"} >
                            Cancel
                    </div>
                    </div>
                </div>
            </Draggable>)
    else
        return (
            <Draggable handle=".hak-modal-header-label-secondlevel" bounds={"#draggable-bounds"}>
                <div className={"hak-modal-settings"}>
                    <Header icon={{ label: "Camp Calamity Settings Menu" }} close={close} secondLevel={true} />
                    <div className={"hak-settings-inside"}>
                        <div onClick={openLogoutConfirmation} className={"btn btn-dark"} >
                            Exit Game
                    </div>
                        <div style={{ height: "15px" }}>

                        </div>
                        <div onClick={openResetConfirmation} className={"btn btn-dark"}>
                            Restart Game
                </div>
                    </div>
                </div>
            </Draggable>

        )
}

export default Settings;