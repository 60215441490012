import React, { useState } from 'react';
import Div100vh from 'react-div-100vh';
import { useHistory } from "react-router-dom";
import "./css/Layout.css";

import Home from './Home';
import Memories from './Memories';
import History from './History';
import Staff from './Staff';
import Forum from './Forum';
import SoundsOfAshburne from './SoundsOfAshburne';

import { assets } from "./assets/assets.config"

const { baseURL, staff: {
    StaffYellowBarNameplatePNG2
}, home: {
    BackgroundIMG
}, layout: {
    HeaderLogoSVG,
    FooterLogoSVG,
    CloseButtonSVG,
    HamburgerMenuSVG,
    HeaderDesktopSVG,
    FooterDesktopSVG,
    HeaderMobileSVG,
    FooterMobileSVG,
    HamburgerMenuSlideoutSVG,
    HamburgerMenuDividerSVG,
    HamburgerMenuHighlightSVG
} } = assets;



export const routes = [
    { name: 'Home', component: Home },
    { name: 'Memories', component: Memories },
    { name: 'History', component: History },
    { name: 'Staff', component: Staff },
    { name: 'Forum', component: Forum },
    { name: 'Sounds of Ashburne', component: SoundsOfAshburne },
];

function HeaderDesktop(props) {
    const { onClose, setActiveRoute, activeRoute } = props || {};
    const selectRoute = (route) => {
        document.getElementById("LayoutBody").scrollTo({
            behavior: "auto",
            top: 0
        });


        setActiveRoute && setActiveRoute(route);
    }
    return (<div id="Header">
        <div id="HeaderDesktop" className="header-desktop d-none d-lg-flex" style={{ backgroundImage: `url(${baseURL + HeaderDesktopSVG})` }}>
            <img src={baseURL + HeaderLogoSVG} alt={"HeaderLogoSVG"} className="header-logo-desktop pointer" onClick={() => selectRoute(routes[0])} />
            <MenuDesktop selectRoute={selectRoute} activeRoute={activeRoute} />
            <button className={"close-icon bg-transparent btn p-2"} onClick={() => onClose && onClose()} href={"#"}><img src={baseURL + CloseButtonSVG} alt={"CloseButtonSVG"} /></button>
        </div>
    </div>);
}

function HeaderMobile(props) {
    const { onClose } = props || {};
    return (<div id="Header">
        <div id="HeaderMobile" className="header-mobile d-flex d-lg-none" style={{ backgroundImage: `url(${baseURL + HeaderMobileSVG})` }}>
            <div className={"menu-icon-container"}>
                <button className={"hamburger-menu-icon bg-transparent btn p-2 m-2"} onClick={() => props?.toggleMenu(true)} href={"#"}><img src={baseURL + HamburgerMenuSVG} alt={"HamburgerMenuSVG"} /></button>
                <button className={"close-icon bg-transparent btn p-2 m-2"} onClick={() => onClose && onClose()} href={"#"}><img src={baseURL + CloseButtonSVG} alt={"CloseButtonSVG"} /></button>
            </div>
            <img src={baseURL + HeaderLogoSVG} alt={"HeaderLogoSVG"} className="header-logo-mobile" />
        </div>
    </div>);
}

function Footer(props) {
    return (<div id="Footer" className={"d-flex vw-100"}>
        <div id="FooterDesktop" className="d-none d-lg-flex vw-100 footer-desktop" style={{ backgroundImage: `url(${baseURL + FooterDesktopSVG})` }}>
            <div className="mx-5 my-3 align-items-center justify-content-center d-flex flex-column">
                <img src={baseURL + FooterLogoSVG} alt={"FooterLogoSVG"} className="footer-logo-desktop" />
                <p className="text-white font-weight-bold text-center p-2 Font-Trilby">ADIRONDACK,<br />NEW YORK</p>
            </div>
        </div>
        <div id="FooterMobile" className="d-flex d-lg-none vw-100 footer-mobile" style={{ backgroundImage: `url(${baseURL + FooterMobileSVG})` }}>
            <img src={baseURL + FooterLogoSVG} alt={"FooterLogoSVG"} className="footer-logo-mobile" />
            <p className="text-white font-weight-bold text-center p-2 Font-Trilby">ADIRONDACK,<br />NEW YORK</p>
        </div>
    </div>)
}

function MenuDesktop(props) {
    const { selectRoute, activeRoute } = props || {};
    return (<div className="d-flex h-100 w-100">
        <nav className="navbar navbar-expand-lg navbar-light w-100 px-lg-3 px-2 mt-5 align-items-start justify-content-end">
            <div className="d-none d-lg-flex w-100" id="navbarNav">
                <ul className="navbar-nav d-flex w-100 justify-content-around" id="navbarList">
                    {routes.map((route) => {
                        if (route?.name === "Home")
                            return null;
                        else
                            return (
                                <li className={`nav-item menu-item font-weight-bold Font-Trilby`} key={route.name} style={route.name === activeRoute.name ? { backgroundImage: `url(${baseURL + StaffYellowBarNameplatePNG2})` } : undefined}>
                                    <span className={`nav-link text-white`} onClick={() => selectRoute(route)} >{route.name} </span>
                                </li>)
                    })}
                </ul>
            </div>
        </nav>
    </div>);
}

function MenuMobile(props) {
    const { setActiveRoute, activeRoute } = props || {};
    const selectRoute = (route) => {
        document.getElementById("LayoutBody").scrollTo({
            behavior: "auto",
            top: 0
        });

        setActiveRoute && setActiveRoute(route);
        props?.toggleMenu(false)
    }
    if (props?.display) {
        return (<Div100vh dontresize="true" className="vh-100 vw-100 floating-menu d-flex d-lg-none">
            <div className={"floating-menu-backdrop"} onClick={() => props?.toggleMenu(false)} />
            <div className={"floating-menu-content"} style={{ backgroundImage: `url(${HamburgerMenuSlideoutSVG})` }}>
                <img src={baseURL + HeaderLogoSVG} alt={"HeaderLogoSVG"} className="header-logo-mobile ml-5 h-25 pointer" onClick={() => selectRoute(routes[0])} />
                <ul className="navbar-nav d-flex w-100 align-items-center my-4" id="navbarList">
                    {routes.map((route) => {
                        if (route?.name === "Home")
                            return null;
                        else
                            return (
                                <li className={"nav-item menu-item-mobile font-weight-bold Font-Trilby mr-4"} key={route.path} style={route.name === activeRoute.name ? { backgroundImage: `url(${baseURL + HamburgerMenuHighlightSVG})` } : undefined}>
                                    <div className={"menu-divider"} style={{ backgroundImage: `url(${HamburgerMenuDividerSVG})` }} />
                                    <span className={`nav-link text-white menu-link-text my-2 ml-5`} onClick={() => selectRoute(route)}> {route.name} </span>
                                </li>)
                    })}
                </ul>
            </div>
        </Div100vh>);
    }
    return null;
}

export default function WebLayout(props) {
    let history = useHistory();
    const [activeRoute, setActiveRoute] = useState(routes[0]);
    const onClose = () => {
        history.push("/")
    }
    const [displayMobileMenu, displayMenu] = useState(false);
    return (<Div100vh dontresize="true" className="vh-100 vw-100 bg-white" id="CampAshburn">
        <HeaderDesktop onClose={onClose} setActiveRoute={setActiveRoute} activeRoute={activeRoute} />
        <MenuMobile display={displayMobileMenu} toggleMenu={displayMenu} setActiveRoute={setActiveRoute} activeRoute={activeRoute} />
        <div id={"LayoutBody"} className={"d-flex flex-column layout-body"} style={activeRoute.name === "Home" ? { backgroundImage: `url(${baseURL + BackgroundIMG})` } : {}}>
            <div className={"h-100 d-flex flex-column justify-content-between"}>
                <div className="d-none d-lg-block"></div>
                <HeaderMobile toggleMenu={displayMenu} onClose={onClose} />
                <div className="layout-body-content">
                    {activeRoute && activeRoute.component ? <activeRoute.component /> : null}
                </div>
                <Footer />
            </div>
        </div>
    </Div100vh>);
}