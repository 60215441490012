import Div100vh from 'react-div-100vh';
import './builder/builder.css';

const Landing = ({setIsEntered}) => {
  const enterClicked = () => {
    setIsEntered(true);
  }

  return (<Div100vh dontresize="true" className="vh-100 vw-100 hak-desktop" >
      <button className={"btn btn-secondary hak-login-button"} onClick={() => enterClicked()}>ENTER</button>    
  </Div100vh>)
};

export default Landing;