import React from 'react';

import { Container, Col, Row } from 'react-bootstrap'

import "./css/Memories.css"

import { assets } from "./assets/assets.config"

const { baseURL, memories: {
    MemoriesPhilomenaYelloBarPNG,
    MemoriesYellowBarPNG,
    MemoriesSadiyaJPG,
    MemoriesJemmaJPG,
    MemoriesCamperCurtisJPG,
    MemoriesAdrienneJPG,
    MemoriesElliotJPG,
    MemoriesBradJPG,
    MemoriesHayleyJPG,
    MemoriesKayakkidsJPG,
    MemoriesRopesKidJPG,
    MemoriesSoccerKidsJPG,
    MemoriesRunningKidsJPG,
    MemoriesMarchmellowKidsJPG,
    MemoriesRamilJPG,
    MemoriesTitleSVG,
    MarcusQuoteSVG,
    RopeKidQuoteSVG,
    AdrienneQuoteSVG,
    BradQuoteSVG
} } = assets;


function Media(props) {
    return <div className={`d-flex media-container ${props?.className}`} style={props?.backgroundImage ? { backgroundImage: `url(${baseURL + props?.backgroundImage})` } : undefined}>
        {props?.children}
    </div>
}

export default function Memories(props) {
    return (<div className="memories-content d-flex align-items-center flex-column justify-content-center" style={{ backgroundImage: `url(${baseURL + MemoriesYellowBarPNG})` }}>
        <div className={"d-flex align-items-center flex-column w-100 memories-title-svg"}>
            <div className={"col-md-8"}>
                <img alt={"MemoriesTitleSVG"} src={baseURL + MemoriesTitleSVG} className={"d-flex"} />
            </div>
        </div>
        <div className={"wrapper"}>
            <Container fluid>
                <Row>
                    <Col md={6}>
                        <Media backgroundImage={MemoriesSadiyaJPG} className={"memoriessadiya"} />
                    </Col>
                    <Col md={6} className={"d-flex flex-column justify-content-between"}>
                        <Row>
                            <Col>
                                <Media backgroundImage={MemoriesRamilJPG} className={"memories-ramil"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Media backgroundImage={MarcusQuoteSVG} className={"diannequote text-white flex-column Font-Futura align-items-center justfy-content-center"}>
                                    <div className={"d-flex h-100 flex-column justify-content-center dianne-quote-text"}>
                                        “It’s not just a place where we have a lot of fun, we also learn a lot too.”
                                        <br />
                                        <span className={"Font-Trilby font-weight-bold"}>- Dianne, camper</span>
                                    </div>
                                </Media>
                            </Col>
                            <Col md={6}>
                                <Media backgroundImage={MemoriesKayakkidsJPG} className={"memories-kayak-kids"} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <Row>
                            <Col md={8}>
                                <Media backgroundImage={MemoriesRopesKidJPG} className={"memories-ropes-kid"} >
                                    <div className={"memories-ropes-kid-overlay flex-column d-flex justify-content-end"}>

                                        <div style={{ backgroundImage: `url(${baseURL + RopeKidQuoteSVG})` }} className={"memories-ropes-kid-quote Font-Futura p-4"}>
                                            "I love Camp Ashburne, and I never wanted the session to end."
                                            <br />
                                            - Ajay, camper
                                        </div>
                                    </div>
                                </Media>
                            </Col>
                            <Col md={4}>
                                <Media backgroundImage={MemoriesElliotJPG} className={"memories-elliot"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Media backgroundImage={MemoriesSoccerKidsJPG} className={"memories-soccer-kids"} />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} className={"d-flex flex-column justify-content-between"}>
                        <Row>
                            <Col>
                                <Media backgroundImage={MemoriesJemmaJPG} className={"memories-jemma"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Media backgroundImage={MemoriesPhilomenaYelloBarPNG} className={"quote-philomena"} >
                                    <div className={"d-flex h-100 flex-column justify-content-center dianne-quote-text Font-Trilby font-weight-bold color-red philomena-quote-text"}>
                                        “I wish the camp stayed open all year round!”
                                        <br />
                                        <span className={"Font-Futura philomena-quote-text-name"}>- Philomena, camper</span>
                                    </div>
                                </Media>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className={"d-flex  flex-column justify-content-between"}>
                        <Row>
                            <Col>
                                <Media backgroundImage={MemoriesBradJPG} className={"memories-brad"}>
                                    <div className={"memories-brad-overlay flex-column d-flex justify-content-end"}>
                                        <div style={{ backgroundImage: `url(${baseURL + BradQuoteSVG})` }} className={"memories-brad-quote Font-Futura"}>
                                            "I still have the one-of-a-kind arrowhead I got for winning the archery contest when I was 11! It’s the only arrowhead Ashburne ever handed out."
                                            <br />
                                            <span className={"Font-Trilby font-weight-bold"}>- Bradley, counselor</span>
                                        </div>
                                    </div>
                                </Media>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Media backgroundImage={MemoriesHayleyJPG} className={"memories-hayley"} />
                            </Col>
                        </Row>

                    </Col>
                    <Col md={8}>
                        <Row>
                            <Col md={7}>
                                <Media backgroundImage={MemoriesAdrienneJPG} className={"memories-adrienne"} />
                            </Col>
                            <Col md={5}>
                                <Row>
                                    <Col>
                                        <Media backgroundImage={MemoriesCamperCurtisJPG} className={"memories-curtis"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Media backgroundImage={AdrienneQuoteSVG} className={"adriennequote text-white flex-column Font-Futura align-items-center justfy-content-center"}>
                                            <div className={"d-flex h-100 flex-column justify-content-center adrienne-quote-text"}>
                                                “This isn’t just a job for me, it’s a passion. The kids aren’t just campers, I think of them all like my own family.”
                                                <br />
                                                <span className={"Font-Trilby font-weight-bold"}>- Adrienne Cohen, camp owner and director</span>
                                            </div>
                                        </Media>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Media backgroundImage={MemoriesRunningKidsJPG} className={"memories-running-kids"} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Media backgroundImage={MemoriesMarchmellowKidsJPG} className={"memories-marshmellow-kids"}>
                            <div className={"memories-marcus-overlay flex-column d-flex justify-content-end"}>
                                <div style={{ backgroundImage: `url(${baseURL + MarcusQuoteSVG})` }} className={"memories-marcus-quote Font-Futura"}>
                                    "The friends I made at camp are going to be my friends forever!"
                                    <br />
                                    <span className={"Font-Trilby font-weight-bold"}>- Marcus, camper</span>
                                </div>
                            </div>
                        </Media>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>);
}
