import React from 'react'
import { Header } from './_shared/ModalHeader';
import { contents } from '../contents/content';
import './_shared/viewer.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Draggable from 'react-draggable'

const Viewer = ({ icon, close }) => {

    return (
        <Draggable handle=".hak-modal-header-label-secondlevel" bounds={"#folder-bounds"}>
            <div className={"hak-modal-audio"}>
                <Header icon={icon} close={close} secondLevel={true} />
                <AudioPlayer autoPlay src={contents[icon.id]} className="hak-audioplayer" />
            </div>
        </Draggable>
    )
}

export default Viewer;