export const contents = {
    "jemma-note-1": "https://cdn.huntakiller.com/summer-camp/jemma/{stage}.txt",
    "new_recording1" : "https://cdn.huntakiller.com/summer-camp/jemma/hakcamp-jcvoicememo.mp3",
    "new_recording2" : "https://cdn.huntakiller.com/summer-camp/jemma/hakcamp-arrestaudio.mp3",
    "thankyou" : "https://cdn.huntakiller.com/summer-camp/jemma/hakcamp-epilogue.wav",
    "it-note-1" : "https://cdn.huntakiller.com/summer-camp/IT/BRN.txt",
    "radioshow-audio-1" : "https://cdn.huntakiller.com/summer-camp/radioshow/hakcamp-outtake1.wav",
    "radioshow-audio-2" : "https://cdn.huntakiller.com/summer-camp/radioshow/hakcamp-outtake2.wav",
    "radioshow-audio-3" : "https://cdn.huntakiller.com/summer-camp/radioshow/hakcamp-outtake3.wav",
    "radioshow-audio-4" : "https://cdn.huntakiller.com/summer-camp/radioshow/hakcamp-outtake4.wav",
    "director-pdf-1": ["https://cdn.huntakiller.com/summer-camp/director/hakcamp-safetyinspection1.1.jpg","https://cdn.huntakiller.com/summer-camp/director/hakcamp-safetyinspection1.2.jpg"],
    "director-pdf-2": ["https://cdn.huntakiller.com/summer-camp/director/hakcamp-safetyinspection2.1.jpg", "https://cdn.huntakiller.com/summer-camp/director/hakcamp-safetyinspection2.2.jpg"],
    "director-pdf-3": ["https://cdn.huntakiller.com/summer-camp/director/saved-emails1.png"],
    "director-pdf-4": ["https://cdn.huntakiller.com/summer-camp/director/saved-emails2.png"],
    "grayline": "https://cdn.huntakiller.com/summer-camp/icons/grayline.png"
}