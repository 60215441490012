import React from 'react';

import "./css/Forum.css"

import { assets } from "./assets/assets.config"

const { baseURL, forum: {
    ForumYellowBarPNG,
    ForumTitleSVG,
    ForumPostbarSVG,
    ForumPostDividerSVG
} } = assets;

const forumComments_7_27 = [
    { date: "7/27", heading: "ADRIENNE COHEN", comment: "I’m heartbroken to share with the Camp Ashburne family that one of our staff members, Bradley Brooks, passed away in an accident a few days ago. Bradley was a camper here for many years and returned to serve as a counselor. He will be remembered by all who knew him as a bright, lively, and creative young man who loved to tell jokes. We will all miss him very much.", level: 1 },
    { date: "7/27", heading: "BRYAN CERK", comment: "I’m so sorry for your loss.", level: 2 },
    { date: "7/27", heading: "ILANA WEBER", comment: "May his memory be a blessing. We are devastated to learn this. If there’s anything the Weber family can do at this time, please let us know.", level: 2 },
    { date: "7/27", heading: "KARI MCKIBBEN", comment: "That’s horrible! Are you still going to have the second session? I’m planning on bringing Jesse, so if there’s a change in plans could you let me know as soon as you can?", level: 2 },
    { date: "7/27", heading: "ADRIENNE COHEN", comment: "Session II will begin without any delay. The camp staff has taken a few days to grieve this loss, but we are now fully focused on preparing to receive campers. Thank you all for your concern.", level: 2 },
    { date: "7/27", heading: "LAURA GREEN", comment: "this young man Bradley, must have been the one responsible for all the prank fires this session !!! If youd done something about it sooner maybe this would not have happened!!!!!", level: 2 },
]

const forumComments_7_22 = [
    { date: "7/22", heading: "TAMIKA CARROLL", comment: "We’re looking forward to coming for Parents' Day tomorrow! Regina’s had a great time, and she says we might even get to see the Burning Baron ourselves!", level: 1 },
    { date: "7/22", heading: "ADRIENNE COHEN", comment: "The kids do love putting together the Parents' Day talent show. I would not be surprised at anything they want to show you.", level: 2 },
    { date: "7/22", heading: "CYRIL DOMAN", comment: "So you are reading this forum Adrienne. You’re just not replying to anybody who has anything negative to say. Good to know.", level: 2 },
]

const forumComments_7_21 = [
    { date: "7/21", heading: "Cyril Doman", comment: "Mrs. Cohen, you have refused to return my phone calls, and you’ve deleted my previous comment on this page. But I can assure you that when I arrive to pick up Dianne in a few days, you will explain to me who stole her earrings from out of her cabin, how this kind of behavior is permitted at your camp, and why you and your staff has been wholly uninterested in doing anything about the rash of thefts I understand have occurred this summer. Those earrings were gifted to Dianne from her godmother, who was gifted them by the third cousin of a Duchess. This is a gift that we cannot just go out and replace and this is completely unacceptable.", level: 1 },
    { date: "7/21", heading: "Amina Riad", comment: "What is going on at that camp?", level: 2 },
    { date: "7/21", heading: "Penelope David", comment: "It’s really gone downhill in the past few years. My kids have gone for for the last few summers, but I don’t know if I’ll send them back next year", level: 2 },
    { date: "7/21", heading: "dikrepus", comment: "third cousin of the duchess sounds like shes nobody ha ha ha", level: 2 },
    { date: "7/21", heading: "Cyril Doman", comment: "How dare you?", level: 2 },
]
const forumComments_7_18 = [
    { date: "7/18", heading: "Charity Dominguez", comment: "I just think that other parents deserve to know about some strange things going on at Camp Ashburne. My son told me that he woke up in the middle of the night one night to find an unattended fire burning right next to his cabin. I have some serious concerns about the safety of things at the camp.", level: 1 },
    { date: "7/19", heading: "Laura Green", comment: "I heard the same thing from my daughter Zoey!! Someone set a fire near their cabins in the MIDDLE OF THE NIGHT and her counselor Hayley told her that nobody knew who it was. Zoey could NOT go back to sleep she was so SCARED!!! WHAT is going ON!??", level: 2 },
    { date: "7/19", heading: "Felipe Marks", comment: "My kid says that his counselor, Brad, told his group that the fires were the work of a ghost. Some campfire story about a Burning Baron. I think he was actually kind of enjoying it. Like Bloody Mary when we were young. Children like to scare each other, you know.", level: 2 },
    { date: "7/20", heading: "Laura Green", comment: "Zoey does not like it one bit she called me from the camp office yesterday begging to come home. She even said someone set her counselors shoelaces on fire, Can you believe!!!", level: 2 },
    { date: "7/20", heading: "Ivy Pellegrino", comment: "Tiger wrote me that his group went on an overnight hike, and when they got to the campsite, the Burning Baron had a fire waiting for them… this is downright eerie.", level: 2 },
    { date: "7/20", heading: "Adrienne Cohen", comment: "Thanks everyone for sharing your comments and concerns. Camp certainly can be an exciting time in a lot of different ways. The “Burning Baron” is our local legend in this part of the state, and a story that has been told around campfires for generations. We don’t encourage this kind of behavior, but I can assure you that everything is perfectly safe. At no time has there been any fire that was not safely contained in one of our fire pits, and all of our counselors are trained in fire safety. At Camp Ashburne, we take our values seriously, and one of those values is Teamwork. We all work together to make a safe and fun environment, and we will continue to do so. Thank you all.", level: 1 },
    { date: "7/20", heading: "Laura Green", comment: "I dont think it’s funny when someone is LITERALLY playing with fire around Zoey!!!", level: 2 },
    { date: "7/20", heading: "dikrepus", comment: " I mean, it’s kinda funny", level: 2 },
]
const forumComments_7_8 = [
    { date: "7/8", heading: "Etienne Furst", comment: "Adrienne, I tried to reach you by phone but have been unable to speak with you. Timmy brought his fidget toys with him to camp. They are very important in keeping him grounded. But he told me that he can’t find them and he’s looked everywhere. It’s been three days, and I can tell even by his voice that he needs them. These things are quite pricey and he’s inconsolable without them. I would appreciate it if you could call me back as soon as you see this.", level: 1 },
    { date: "7/9", heading: "Alyssa Butler", comment: "Jakob said that somebody took his portable chess set last Friday morning. Do they have a thievery problem at that camp?", level: 2 },
    { date: "7/14", heading: "Etienne Furst", comment: "Alyssa, I’m sorry to hear that. Adrienne still hasn’t gotten back to me, and I’ve been told Timmy can report something missing but he hasn’t been able to get a hold of her either. He called again today to say that one of the girl campers was making a big fuss because her diamond earrings had been stolen this morning, so I’d guess you’re right about a thief. I’d heard so many good things about Ashburne, but at the moment I’m very unimpressed.", level: 2 },
]
const forumComments_6_28 = [
    { date: "6/28", heading: "Francisco Campos", comment: "We just got home from dropping Sophie off at the camp, and the grounds look so beautiful. I wish I could have stayed too.", level: 1 },
    ]
const forumComments_6_27 = [
    { date: "6/27", heading: "Serafina Drucker", comment: "Rosa is all packed and ready and she can’t wait to arrive tomorrow!", level: 1 },
    { date: "6/27", heading: "Adrienne Cohen", comment: "And we can’t wait to see her!", level: 2 },
    
]
const forumComments_8_31 = [
    { date: "8/31", heading: "Nicolas Bridge", comment: "I just wanted to say one more time how much Allistair loves coming to Camp Ashburne every year. He’s already started asking if he can stay for both sessions next year!", level: 1 },
]

function Comment(props) {
    return (<div className={`d-flex w-100 align-items-center flex-column ${props?.level === 2 ? 'level-2-comment' : ''}`}>
        <div className={"forum-postbar col-12 col-md-10 text-white Font-Trilby font-weight-bold h3"} style={{ backgroundImage: `url(${baseURL + ForumPostbarSVG})` }}>
            {props?.date}&nbsp;&nbsp;&nbsp;&nbsp;{props?.heading || ""}
        </div>
        <div className={"comment-text col-12 col-md-10 Font-Futura h4"}>
            {props?.comment || ""}
        </div>
    </div>);
}

function Divider(props) {
    return (<div className={"post-divider"} style={{ backgroundImage: `url(${baseURL + ForumPostDividerSVG})` }} />)
}

function CommentDisabled() {
    return (<div className={"comments-disabled h1 Font-Trilby font-weight-bold my-4 text-center"} >
        Comments disabled.
    </div>)

}

export default function Forum(props) {
    return (<div className="forum-content d-flex align-items-center flex-column justify-content-center" style={{ backgroundImage: `url(${baseURL + ForumYellowBarPNG})` }}>
        <div className={"d-flex align-items-center flex-column w-100 forum-title-svg"} >
            <div className={"col-10 col-md-6"}>
                <img src={baseURL + ForumTitleSVG} alt={"ForumTitleSVG"} className={"d-flex"} />
            </div>
        </div>
        <div className={"d-flex align-items-center flex-column w-100 flex-wrap"}>
            <Divider />
            <br />
            <br />
            {forumComments_7_27.map((comment, index) => {
                return (<Comment key={"comment-7-27-" + index} {...comment} />)
            })}
            <CommentDisabled />
            <Divider />
            <br />
            <br />
            {forumComments_7_22.map((comment, index) => {
                return (<Comment key={"comment-7-22-" + index} {...comment} />)
            })}
            <CommentDisabled />
            <Divider />
            <br />
            <br />
            {forumComments_7_21.map((comment, index) => {
                return (<Comment key={"comment-7-21-" + index} {...comment} />)
            })}
            <CommentDisabled />
            <Divider />
            <br />
            <br />
            {forumComments_7_18.map((comment, index) => {
                return (<Comment key={"comment-7-18-" + index} {...comment} />)
            })}
            <CommentDisabled />
            <Divider />
            <br />
            <br />
            {forumComments_7_8.map((comment, index) => {
                return (<Comment key={"comment-7-8-" + index} {...comment} />)
            })}
            <CommentDisabled />
            <Divider />
            <br />
            <br />
            {forumComments_6_28.map((comment, index) => {
                return (<Comment key={"comment-6-28-" + index} {...comment} />)
            })}
            
            <Divider />
            <br />
            <br />
            {forumComments_6_27.map((comment, index) => {
                return (<Comment key={"comment-6-27-" + index} {...comment} />)
            })}
            
            <Divider />
            <br />
            <br />
            {forumComments_8_31.map((comment, index) => {
                return (<Comment key={"comment-8-31-" + index} {...comment} />)
            })}
            
            <Divider />
            <br />
            <br />
        </div>

    </div>);
}