import React, { useContext, useState, useImperativeHandle, forwardRef } from 'react';
import { getIcon } from '../contents/icons';
import { GameStateContext } from '../../context/context';
import { getComponentTag } from '../_shared/utils';
import './builder.css';

const Builder = forwardRef(({ config, openWeb}, ref ) => {

    const [current, setCurrent] = useState(null);
    const context = useContext(GameStateContext);

    useImperativeHandle(ref, () => ({
        closeWindows() {
            setCurrent(null);
        }
    }));

    const close = () => {
        setCurrent(null);
    }

    const iconClick = (icon) => {
        icon.type === "web" ? openWeb() : setCurrent(icon);
    }

    const setIcon = (icon) => {

        let textStyle = ["web", "folder"].includes(icon.type) ? {color: "white"} : {color: "black"};

        if (icon?.lock?.type === "hide" && !context?.gameState?.includes(icon.lock.state)) {
            return <div key={icon.id}></div>
        }
        else {
            return <li key={icon.id} className="col mb-4 " >
                <div className="pt-4 pb-2 hak-icon">
                    <img onClick={() => iconClick(icon)} src={getIcon[icon.type]} alt={icon.label} />
                </div>
                <div className="hak-icon-label" style={textStyle}>{icon.label}</div>
            </li>
        }
    }

    return (
        <>
            <ul className="row row-cols-3 row-cols-sm-4 row-cols-lg-6 row-cols-xl-8 list-unstyled list hak-icon-ul">
                {config.map((icon) => (
                     setIcon (icon) 
                ))}
                <li>{current && current?.type !== "web" && getComponentTag(current, close)}</li>
            </ul>
        </>);

});

export default Builder;