/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { addUserMessage, addResponseMessage, deleteMessages, setBadgeCount, isWidgetOpened, toggleMsgLoader, renderCustomComponent, Widget, markAllAsRead } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import Div100vh from 'react-div-100vh';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { GameStateContext } from '../context/context';
import Builder from './builder/Builder';
import './builder/builder.css';
import config from './builder/config';
import { contents } from './contents/content';
import { getIcon } from './contents/icons';
import Settings from './Settings';
import Landing from './Landing';
import { getSavedMessages, qna, saveMessages } from './_shared/utils';
import { useHistory } from "react-router-dom";




const stage_3_audio_1 = "{new_recording1.mp3}";
const stage_4_audio_1 = "{new_recording2.mp3}";
const stage_4_audio_2 = "{thankyou.mp3}";

const MessageAudio = ({ id }) => {
  return (<AudioPlayer style={{ width: "65%", "marginBottom": "15px" }} src={contents[replaceTokens(id)]} />)
}

const GrayLine = () => {
  return (<img style={{ width: "65%" }} src={contents["grayline"]}></img>)
}

const replaceTokens = (chat) => {
  return chat.replace("{{Correct}}", "").replace("{{Incorrect}}", "").replace("{", "").replace("}", "").replace(".mp3", "");
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}


const saveUserMessage = (message, time = Date.now()) => {
  const savedMessages = getSavedMessages();
  savedMessages.push({
    type: 'user',
    time,
    message
  })
  saveMessages(savedMessages);
}

const saveAudioMessage = (type, id, time = Date.now()) => {
  const savedMessages = getSavedMessages();
  savedMessages.push({
    type,
    id,
    time
  })
  saveMessages(savedMessages);
}

const saveResponseMessage = (message, time = Date.now()) => {
  const savedMessages = getSavedMessages();
  savedMessages.push({
    type: 'response',
    time,
    message
  })
  saveMessages(savedMessages);
}

const addDelayedResponseMessage = (message, index) => {
  saveResponseMessage(message)
  sleep(1500 * index).then(() => {
    addResponseMessage(message);
  });
}

const hydrateChatBox = () => {
  let delay = 50;//ms
  const savedMessages = getSavedMessages();
  savedMessages.forEach((msg, index) => {

    if (msg.type === "user") {
      setTimeout(() => addUserMessage(msg.message), delay * index);
    }
    else if (msg.type === "response") {
      setTimeout(() => addResponseMessage(msg.message), delay * index);
    }
    else if ([stage_3_audio_1, stage_4_audio_1, stage_4_audio_2].includes(msg.type.toLowerCase())) {
      setTimeout(() => renderCustomComponent(MessageAudio, { id: msg.id }), (delay * index) + 60);
    }
    else if (msg.type.toLowerCase() === "{grayline}") {
      setTimeout(() => renderCustomComponent(GrayLine, null), delay * index);
    }

    if (savedMessages.length === index + 1)
      setTimeout(markAllAsRead, delay * index + 1)
  })
}

const Desktop = () => {
  let history = useHistory();

  const { gameState, addState, resetState } = useContext(GameStateContext);
  const no_good_match = "no good match found in kb.";

  useEffect(async () => {
    //stage one message set
    if (gameState?.length === 0) {
      try {
        toggleMsgLoader();
        let response = await qna("stage_1", "01", "1");
        const data = await response.json();
        toggleMsgLoader();
        const chat = data?.answers[0]?.answer;
        chat?.split('|').forEach((element, index) => {
          addDelayedResponseMessage(replaceTokens(element), index);
        });
        if (isWidgetOpened()) {
          !gameState?.includes('stage1_message_read') && addState("stage1_message_read");
        }
      } catch (e) {
        console.log(e);
      }
    }
    //stage two message set
    if (gameState?.includes('it_folder_unlocked') && !gameState?.includes('stage2_message_read')) {
      try {
        toggleMsgLoader();
        let response = await qna("stage_2", "02", "1");
        const data = await response.json();
        toggleMsgLoader();
        const chat = data?.answers[0]?.answer;
        chat?.split('|').forEach((element, index) => {
          addDelayedResponseMessage(replaceTokens(element), index);
        });

        if (isWidgetOpened()) {
          !gameState?.includes('stage1_message_read') && addState("stage1_message_read");
          !gameState?.includes('stage2_message_read') && addState("stage2_message_read");
        }
      } catch (e) {
        console.log(e);
      }
    }

  }, [gameState]);

  const isChatOpened = (val) => {
    if (!gameState?.includes("stage1_message_read")) {
      !gameState?.includes('stage1_message_read') && addState("stage1_message_read");
    }

    if (gameState?.includes('it_folder_unlocked') && !gameState?.includes("stage2_message_read")) {
      !gameState?.includes('stage1_message_read') && addState("stage1_message_read");
      !gameState?.includes('stage2_message_read') && addState("stage2_message_read");
    }
  }

  const handleNewUserMessage = async (newMessage) => {
    saveUserMessage(newMessage);
    //stage three message set
    if (gameState?.includes('it_folder_unlocked') && !gameState?.includes('stage3_message_read')) {
      toggleMsgLoader();
      let response = await qna(newMessage, "03", "1");
      const data = await response.json();
      toggleMsgLoader();
      const chat = data?.answers[0]?.answer;
      if (chat.toLowerCase() === no_good_match) {
        addDelayedResponseMessage('That doesn’t sound right… keep looking?');
      }
      else {
        let correct = false;
        chat?.split('|').forEach((element, index) => {
          if (element.toLowerCase() === stage_3_audio_1) {
            sleep(2000 * index).then(() => {
              renderCustomComponent(MessageAudio, { id: element });
            });
            saveAudioMessage(element.toLowerCase(), element);
          }
          else {
            if (element.indexOf("{{Correct}}") > -1) correct = true;
            addDelayedResponseMessage(replaceTokens(element), index);
          }
        });
        if (correct) {
          !gameState?.includes('stage1_message_read') && addState("stage1_message_read");
          !gameState?.includes('stage2_message_read') && addState("stage2_message_read");
          !gameState?.includes('stage3_message_read') && addState("stage3_message_read");
        }
      }
    }
    //stage four message set
    else if (gameState?.includes('it_folder_unlocked') && !gameState?.includes('epilogue_message_read')) {
      toggleMsgLoader();
      let response = await qna(newMessage, "04", "1");
      const data = await response.json();
      toggleMsgLoader();
      const chat = data?.answers[0]?.answer;
      if (chat.toLowerCase() === no_good_match) {
        addDelayedResponseMessage('That doesn’t sound right… keep looking?');
      }
      else {
        let correct = false;
        chat?.split('|').forEach((element, index) => {
          if (element.toLowerCase() === stage_4_audio_1 || element.toLowerCase() === stage_4_audio_2) {
            sleep(2000 * index).then(() => {
              renderCustomComponent(MessageAudio, { id: element });
            });
            saveAudioMessage(element.toLowerCase(), element);
          }
          else if (element.toLowerCase() === "{grayline}") {
            sleep(2000 * index).then(() => {
              renderCustomComponent(GrayLine, null);
            });
            saveAudioMessage(element.toLowerCase(), element);
          }
          else {
            if (element.indexOf("{{Correct}}") > -1) correct = true;
            {
              addDelayedResponseMessage(replaceTokens(element), index);
            }
          }
        });
        if (correct) {
          !gameState?.includes('stage1_message_read') && addState("stage1_message_read");
          !gameState?.includes('stage2_message_read') && addState("stage2_message_read");
          !gameState?.includes('stage3_message_read') && addState("stage3_message_read");
          !gameState?.includes('epilogue_message_read') && addState("epilogue_message_read");
        }
      }
    }
    else {
      addDelayedResponseMessage("[Away Message] I’m not at the computer right now, sorry!");
    }
  };

  useEffect(() => {
    hydrateChatBox()
  }, [])

  const openWeb = () => {
    history.push("/campashburne")
  }

  const childRef = useRef();

  //settings
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const closeSettings = () => {
    setIsSettingsOpen(false);
    childRef.current.closeWindows();
  }
  const openSettings = () => {
    setIsSettingsOpen(true);
  }
  const [isEntered, setIsEntered] = useState(false);
  
  if (isEntered) {
    return (<Div100vh dontresize="true" className="vh-100 vw-100 hak-desktop" >
      <div style={{ "border": "1px solid transparent", "height": "100%" }} id="draggable-bounds">
        <Builder config={config} openWeb={openWeb} ref={childRef} />
        <div onClick={() => openSettings()} className={"hak-settings"}>
          <img style={{ height: "60px" }} src={getIcon.settings} alt="Settings" />
        </div>
        {isSettingsOpen && <Settings close={closeSettings} deleteMessages={deleteMessages} setBadgeCount={setBadgeCount} resetState={resetState} />}
        <div onClick={() => isChatOpened(isWidgetOpened())}>
          <Widget
            handleNewUserMessage={handleNewUserMessage}
            title="Jemma Cohen"
            subtitle={null}
          />
        </div>
      </div>
    </Div100vh>)
  }
  else {
      return (<Landing setIsEntered={setIsEntered} />)
  }
};

export default Desktop;