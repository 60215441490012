import React from 'react';

import "./css/Staff.css"

import { assets } from "./assets/assets.config"

const { baseURL, staff: {
    StaffYellowBarPNG,
    StaffYellowBarNameplatePNG1,
    StaffYellowBarNameplatePNG2,
    StaffAdrienneJPG,
    StaffBradleyJPG,
    StaffCurtisJPG,
    StaffElliotJPG,
    StaffHayleyJPG,
    StaffJoelJPG,
    StaffRoyceJPG,
    StaffSadiyaJPG,
    StaffJemmaJPG,
    StaffRamilJPG,
    StaffTitleSVG
} } = assets;

const staffList = [
    { name: 'Adrienne Cohen', role: 'Camp Owner and Director', image: baseURL + StaffAdrienneJPG },
    { name: 'Sadiya Ahmadi', role: 'Head Counselor', image: baseURL + StaffSadiyaJPG },
    { name: 'Ramil Macatangay', role: 'Head Counselor', image: baseURL + StaffRamilJPG },
    { name: 'Jemma Cohen', role: 'Counselor', image: baseURL + StaffJemmaJPG },
    { name: 'Bradley Brooks', role: 'Counselor', image: baseURL + StaffBradleyJPG },
    { name: 'Hayley Price', role: 'Counselor', image: baseURL + StaffHayleyJPG },
    { name: 'Elliot Young', role: 'Counselor', image: baseURL + StaffElliotJPG },
    { name: 'Royce Haddock', role: 'Groundskeeper', image: baseURL + StaffRoyceJPG },
    { name: 'Joel Mendes', role: 'Maintenance', image: baseURL + StaffJoelJPG },
    { name: 'Curtis Williams', role: 'Cook', image: baseURL + StaffCurtisJPG },
]

export default function Staff(props) {
    return (<div className="staff-content d-flex align-items-center flex-column justify-content-center" style={{ backgroundImage: `url(${baseURL + StaffYellowBarPNG})` }}>
        <div className={"d-flex align-items-center flex-column w-100 staff-title-svg"}>
            <div className={"col-10 col-md-4"}>
                <img alt={"StaffTitleSVG"} src={baseURL + StaffTitleSVG} className={"d-flex"} />
            </div>
        </div>
        <div className={"d-flex align-items-center flex-column w-100 flex-wrap"}>
            <div className={"col-10 col-md-6 col-lg-5 Font-Trilby h1 text-center d-none d-md-flex font-weight-bold"}>
                Here are the folks behind your unforgettable summer
            </div>
        </div>
        <div className={"d-flex col-md-10 flex-wrap justify-content-center flex-row w-100 flex-wrap"}>
            {staffList.map((staff, index) => {
                return (<div key={"staff-" + index} className={"col-sm-6 col-md-4 col-lg-3 staff-frame d-flex flex-column"}>
                    <div className={"col-12 p-0 d-flex flex-column align-items-center"} >
                        <div className={"staff-image-container col-12 p-0"} >
                            <img alt={staff.name} src={staff.image} className={"staff-image"} />
                        </div>
                        <div className={"staff-overlay text-center Font-Trilby color-red "} style={{ backgroundImage: `url(${Math.random() > 0.5 ? baseURL + StaffYellowBarNameplatePNG2 : baseURL + StaffYellowBarNameplatePNG1})` }}>
                            <span className={"staff-text font-weight-bold p-0 m-0"}>{staff.name},</span>
                            <span className={"staff-text"}>{staff.role}</span>
                        </div>
                    </div>

                </div>);
            })}
        </div>
    </div>);
}