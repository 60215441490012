import React, { useState, useContext, useEffect } from 'react'
import Builder from '../builder/Builder';
import { Header } from './_shared/ModalHeader';
import PasswordIt from './Password';
import { GameStateContext } from '../../context/context';
import Draggable from 'react-draggable'
import './_shared/viewer.css';

const Viewer = ({ icon, close }) => {
    const context = useContext(GameStateContext);

    const [passed, setPass] = useState();
    const passCheck = (passed) => {
        context.addState(icon.lock.state);
        setPass(passed);
    }

    useEffect(() => {
        if (context?.gameState?.includes(icon.lock.state)) {
            setPass(true);
        }
    }, [context?.gameState, icon.lock.state])

    return (
        <Draggable handle=".hak-modal-header-label" bounds={"#draggable-bounds"}>
            <div className={"hak-modal"} id={"folder-bounds"}>
                <Header icon={icon} close={close} />
                {!passed && <PasswordIt icon={icon} passCheck={passCheck} close={close} />}
                {passed && <Builder config={icon.children} />}
            </div>
        </Draggable>
    )
}

export default Viewer;