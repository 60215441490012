import React, { useState } from 'react'
import { contents } from '../contents/content';
import { Header } from './_shared/ModalHeader';
import './_shared/viewer.css';
import Draggable from 'react-draggable'

const PDFViewer = ({ icon, close }) => {

  const [loading, setLoading] = useState(true);
  const imageLoaded = () => setLoading(false);

  const [current, setCurrent] = useState(0);
  const backClick = () => setCurrent(0);
  const fwClick = () => setCurrent(1);

  const [imgWidth, setImgWidth] = useState(100)
  const [fetching, setFetching] = useState(false)

  const zoom = (zoomIn) => {
    let widthPercent = imgWidth;
    widthPercent = zoomIn ? Math.max(widthPercent - 20, 100) : Math.min(widthPercent + 20, 350);
    setImgWidth(widthPercent);
  };

  const download = (url, name, e) => {
    e.preventDefault();
    if (!fetching) {
      setFetching(true);
      fetch(url + "?crossorigin")
        .then(response => response.blob())
        .then(blob => {
          setFetching(false);
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style = "display: none";

          a.download = name;
          document.body.appendChild(a);
          a.click();
        })
        .catch(console.log("Error downloading image."));
    }
  };

  return (<Draggable handle=".hak-modal-header-label-secondlevel" bounds={"#folder-bounds"}>
    <div className={"hak-modal-txt"}>
      <Header icon={icon} close={close} secondLevel={true} />
      {loading && <div className={"center"}>Loading..</div>}
      {<div style={{ "overflowY": "scroll", "height": "25em" }}>
        <img
          style={{ width: imgWidth + "%", display: loading ? 'none' : 'block' }}
          onLoad={imageLoaded}
          src={contents[icon.id][current]}
          alt={"File not available!"}
        />
      </div>}
      {
        <div className={"center"}>
          {contents[icon.id].length > 1 && <button className={"btn btn-sm btn-dark"} style={{ "margin-right": "1em" }} onClick={backClick}>Page 1 of 2</button>}
          {contents[icon.id].length > 1 && <button className={"btn btn-sm btn-dark pr-2"} style={{ "margin-right": "1em" }} onClick={fwClick}>Page 2 of 2</button>}
          <button disabled={imgWidth >= 350} className={"btn btn-sm"} onClick={() => zoom(false)}><img style={{ width: "20px" }} src="https://cdn.huntakiller.com/huntakiller/s10/zoomin-icon.svg" alt={"Zoom In"} /></button> 
             <button disabled={imgWidth <= 100} className={"btn btn-sm"} onClick={() => zoom(true)}><img style={{ width: "20px" }} src="https://cdn.huntakiller.com/huntakiller/s10/zoomout-icon.svg" alt={"Zoom Out"} /></button> 
             <button className={"btn btn-sm"} onClick={(e) => download(contents[icon.id][current], icon.label, e)}><img style={{ width: "17px" }} src="https://cdn.huntakiller.com/huntakiller/s10/download-icon.svg" alt={"Download"} /></button>
        </div>}
    </div>
  </Draggable>
  );
}

export default PDFViewer;