export const assets = {
    baseURL: "https://cdn.huntakiller.com/summer-camp/campashburne",
    forum: {
        ForumYellowBarPNG: "/assets/forum/hakcamp-forumyellowbar.png",
        ForumTitleSVG: "/assets/forum/hakcamp-forumtype.svg",
        ForumPostbarSVG: "/assets/forum/hakcamp-forumpostbar-2.svg",
        ForumPostDividerSVG: "/assets/forum/hakcamp-forumpostdivider.svg"
    },
    history: {
        HistoryYellowBarPNG: "/assets/history/hakcamp-historyyellowbar-desktop.png",
        HistoryAdrienneAndJemmaJPG: "/assets/history/hackcamp-historyadrienneandjemma.jpg",
        HistoryTitleSVG: "/assets/history/hakcamp-historytype.svg"
    },
    home: {
        BackgroundIMG: "/assets/home/hakcamp-homeheroimg.jpg",
        HomeSVG: "/assets/home/hakcamp-hometype.svg"
    },
    memories: {
        MemoriesPhilomenaYelloBarPNG: "/assets/memories/hakcamp-memoriesyellowbar-philomenaquote.png",
        MemoriesYellowBarPNG: "/assets/memories/hakcamp-memoriesyellowbar-desktop.png",
        MemoriesSadiyaJPG: "/assets/memories/hakcamp-memoriessadiya.jpg",
        MemoriesJemmaJPG: "/assets/memories/hakcamp-memoriesjemma.jpg",
        MemoriesCamperCurtisJPG: "/assets/memories/hakcamp-memoriescampercurtis.jpg",
        MemoriesAdrienneJPG: "/assets/memories/hakcamp-memoriesadrienne.jpg",
        MemoriesElliotJPG: "/assets/memories/hakcamp-memorieselliot.jpg",
        MemoriesBradJPG: "/assets/memories/hakcamp-memoriesbrad.jpg",
        MemoriesHayleyJPG: "/assets/memories/hakcamp-memorieshayley.jpg",
        MemoriesKayakkidsJPG: "/assets/memories/hakcamp-memorieskayakkid.jpg",
        MemoriesRopesKidJPG: "/assets/memories/hakcamp-memoriesropeskid-withouttext.jpg",
        MemoriesSoccerKidsJPG: "/assets/memories/hakcamp-memoriessoccerkids.jpg",
        MemoriesRunningKidsJPG: "/assets/memories/hakcamp-memoriesrunningkids.jpg",
        MemoriesMarchmellowKidsJPG: "/assets/memories/hakcamp-memoriesmarshmellowkids.jpg",
        MemoriesRamilJPG: "/assets/memories/hakcamp-memoriesramil.jpg",
        MemoriesTitleSVG: "/assets/memories/hakcamp-memoriestype.svg",
        MarcusQuoteSVG: "/assets/memories/hakcamp-memoriesredbox-marcusquote.svg",
        RopeKidQuoteSVG: "/assets/memories/hakcamp-memoriesredbox-ropekidquote.svg",
        AdrienneQuoteSVG: "/assets/memories/hakcamp-memoriesredbox-adriennequote.svg",
        BradQuoteSVG: "/assets/memories/hakcamp-memoriesredbox-bradquote.svg",


    },
    soundsOfAshburne: {
        SoundsYellowBarPNG: "/assets/soundsofashburne/hakcamp-soundsyellowbar-desktop.png",
        SoundsPhotoJPG: "/assets/soundsofashburne/hakcamp-soundsphoto.jpg",
        SoundsOfAshburneMP3: "/assets/soundsofashburne/hakcamp-soundsofashburne.wav",
        SoundsPlayButtonSVG: "/assets/soundsofashburne/hakcamp-soundsplaybutton.svg",
        SoundsPauseButtonSVG: "/assets/soundsofashburne/hakcamp-soundspausebutton.svg",
        SoundsTitleSVG: "/assets/soundsofashburne/hakcamp-soundstype.svg",
        FileNameBarSVG: "/assets/soundsofashburne/hakcamp-soundsfilenamebar.svg"

    },
    staff: {
        StaffYellowBarPNG: "/assets/staff/hakcamp-staffyellowbar-desktop.png",
        StaffYellowBarNameplatePNG1: "/assets/staff/hakcamp-staffyellowbarnameplate1.png",
        StaffYellowBarNameplatePNG2: "/assets/staff/hakcamp-staffyellowbarnameplate2.png",
        StaffAdrienneJPG: "/assets/staff/hakcamp-staffadrienne.jpg",
        StaffBradleyJPG: "/assets/staff/hakcamp-staffbradley.jpg",
        StaffCurtisJPG: "/assets/staff/hakcamp-staffcurtis.jpg",
        StaffElliotJPG: "/assets/staff/hakcamp-staffelliot.jpg",
        StaffHayleyJPG: "/assets/staff/hakcamp-staffhayley.jpg",
        StaffJoelJPG: "/assets/staff/hakcamp-staffjoel.jpg",
        StaffRoyceJPG: "/assets/staff/hakcamp-staffroyce.jpg",
        StaffSadiyaJPG: "/assets/staff/hakcamp-staffsadiya.jpg",
        StaffJemmaJPG: "/assets/staff/hakcamp-staffjemma.jpg",
        StaffRamilJPG: "/assets/staff/hakcamp-stafframil.jpg",
        StaffTitleSVG: "/assets/staff/hakcamp-stafftype.svg"
    },
    layout: {
        HeaderLogoSVG: "/assets/hakcamp-headerlogo.svg",
        FooterLogoSVG: "/assets/hakcamp-footerlogo.svg",
        CloseButtonSVG: "/assets/hakcamp-closebutton.svg",
        HamburgerMenuSVG: "/assets/mobile/hakcamp-hamburger-mobile.svg",
        HeaderDesktopSVG: "/assets/desktop/hakcamp-header-desktop.svg",
        FooterDesktopSVG: "/assets/desktop/hakcamp-footer-desktop.svg",
        HeaderMobileSVG: "/assets/mobile/hakcamp-header-mobile.svg",
        FooterMobileSVG: "/assets/mobile/hakcamp-footer-mobile.svg",
        HamburgerMenuSlideoutSVG: "/assets/mobile/hakcamp-hamburgermenuslideout-mobile.svg",
        HamburgerMenuDividerSVG: "/assets/mobile/hakcamp-menudivider-mobile.svg",
        HamburgerMenuHighlightSVG: "/assets/mobile/hakcamp-menuhighlight-mobile.png"
    }
}