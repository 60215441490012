const icons = [
    {
        id: "camp-ashburn",
        type: "web",
        label: "Camp Website",
        lock: null
    },
    {
        id: "jemma",
        type: "folder",
        label: "Jemma Cohen",
        lock: { type: "password", state: "jemma_folder_unlocked" },
        children: [
            {
                id: "jemma-note-1",
                type: "txt",
                label: "NOTES.TXT",
                lock: {
                    type: "progressive",
                    state: [
                        { 'key': 'stage1_message_read', 'stage': 'stage-1' },
                        { 'key': 'it_folder_unlocked', 'stage': 'stage-2' },
                        { 'key': 'stage3_message_read', 'stage': 'stage-3' }]
                }
            },
            {
                id: "new_recording1",
                type: "audio",
                label: "New Recording 1",
                lock: { type: "hide", state: "stage3_message_read"},
            },
            {
                id: "new_recording2",
                type: "audio",
                label: "New Recording 2",
                lock: { type: "hide", state: "epilogue_message_read"},
            },
            {
                id: "thankyou",
                type: "audio",
                label: "Thank you",
                lock: { type: "hide", state: "epilogue_message_read"},
            }
        ]
    },
    {
        id: "radioshow",
        type: "folder",
        label: "Radio Show",
        lock: { type: "passwrod", state: "radio_folder_unlocked" },
        children: [
            {
                id: "radioshow-audio-1",
                type: "audio",
                label: "OUTTAKE 1",
                lock: null,
            },
            {
                id: "radioshow-audio-2",
                type: "audio",
                label: "OUTTAKE 2",
                lock: null,
            },
            {
                id: "radioshow-audio-3",
                type: "audio",
                label: "OUTTAKE 3",
                lock: null,
            },
            {
                id: "radioshow-audio-4",
                type: "audio",
                label: "OUTTAKE 4",
                lock: null,
            }
        ]
    },
    {
        id: "it",
        type: "folder",
        label: "IT",
        lock: { type: "IT", state: "it_folder_unlocked" },
        children: [
            {
                id: "it-note-1",
                type: "txt",
                label: "BRN.TXT",
                lock: null
            }
        ]
    },
    {
        id: "director",
        type: "folder",
        label: "Director",
        lock: { type: "password", state: "director_folder_unlocked" },
        children: [
            {
                id: "director-pdf-1",
                type: "pdf",
                label: "inspection_6_1",
                lock: null,
            },
            {
                id: "director-pdf-2",
                type: "pdf",
                label: "inspection_7_24",
                lock: null,
            },
            {
                id: "director-pdf-3",
                type: "pdf",
                label: "CurtisRequest_vumail",
                lock: null,
            },
            {
                id: "director-pdf-4",
                type: "pdf",
                label: "Compliment_vumail",
                lock: null,
            }
        ]
    },
];

export default icons;