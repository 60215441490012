import React, { Suspense } from 'react';
import { AuthProvider } from 'oidc-react';
import WebLayout from './components/camp_ashburne/WebLayout';
// import Landing from './components/Landing';
import Desktop from './components/Desktop';
import 'bootstrap/dist/css/bootstrap.min.css';
import { oidcConfig } from './oidc';
import { GameStateProvider } from './context/provider';
import {BrowserRouter as Router, Route} from 'react-router-dom';

let oidc = oidcConfig[process.env.REACT_APP_ENVIRONMENT]

function App() {
  return (  
    <AuthProvider autoSignIn={false} {...oidc}>
      <GameStateProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            <Route path="/" component={Desktop}/>
            <Route path="/campashburne" component={WebLayout}/>
          </Router>
        </Suspense>
      </GameStateProvider>
    </AuthProvider>
  ); 
}

export default App;
