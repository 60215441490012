import React, { useEffect, useState } from 'react';
import { GameStateContext } from './context';
import { saveMessages } from '../components/_shared/utils';

export function GameStateProvider(props) {

    const local_key = "summer-camp"

    useEffect(() => {
        const initialState = { state: { lastUpdated: null, unlocked: [] }}
        let unlocked = [];
        let val = localStorage.getItem(local_key);
        let localData = (val && JSON.parse(val)) || initialState;

        unlocked = localData?.state?.unlocked || [];
      
        setGameState(unlocked)

    }, []);

    const [gameState, setGameState] = useState(null);

    const postState = (unlocked) => {
        localStorage.setItem(local_key, JSON.stringify({ "state" : { "unlocked": unlocked , "lastUpdated": Date.now() }}));
    }

    return (
        <GameStateContext.Provider
            value={{
                gameState: gameState,
                addState: (newState) => {
                    let state = gameState || [];

                    postState([...state, newState]);
                    setGameState([...state, newState]);
                },
                resetState: () => {
                    postState([]);
                    setGameState([]);
                    saveMessages([]);
                }
            }}
        >
            {props.children}
        </GameStateContext.Provider>
    );
}