import React, { useEffect, useState, useContext } from 'react'
import { Header } from './_shared/ModalHeader';
import { contents } from '../contents/content';
import { GameStateContext } from '../../context/context';
import './_shared/viewer.css';
import { sortBy } from 'lodash-es';
import Draggable from 'react-draggable'


const Viewer = ({ icon, close }) => {
    const [txtContent, setTxtContent] = useState()
    const context = useContext(GameStateContext);

    useEffect(() => {
        let url = null;

        if (!icon.lock) url = contents[icon.id];
        else {
            let sorted = sortBy(icon.lock.state, ['stage']).reverse();
            let stage = null;
            for (let i = 0; i < sorted.length; i++) {
                if (context.gameState.includes(sorted[i].key)) {
                    stage = sorted[i].stage;
                    break;
                }
            }
            url = contents[icon.id].replace("{stage}", stage || sorted[sorted.length-1].stage);
        }

        fetch(url)
            .then((r) => r.text())
            .then(text => {
                setTxtContent(text);
            })
    }, [context.gameState, icon.id, icon.lock]);


    return (
        <Draggable handle=".hak-modal-header-label-secondlevel" bounds={"#folder-bounds"}>
            <div className={"hak-modal-txt"}>
                <Header icon={icon} close={close} secondLevel={true} />
                <div style={{ "overflowY": "auto", "height": "25em", "padding": "20px", "color": "black" }} dangerouslySetInnerHTML={{ __html: txtContent }}></div>
            </div>
        </Draggable>
    )
}

export default Viewer;