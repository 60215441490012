import React, { useState } from 'react'
import { Header } from './_shared/ModalHeader';
import './_shared/viewer.css';
import { FormControl, Button } from 'react-bootstrap';
import PasswrodIT from './PasswordIT';
import Draggable from 'react-draggable'

const Password = ({ icon, passCheck, close }) => {
    const passes = JSON.parse(process.env.REACT_APP_STAGE_PW);

    const error_message_max = 3;

    const [password, setPassword] = useState();
    const [error, setError] = useState();
    const [count, setCount] = useState(0);

    const onChange = val => setPassword(val)
    const btnClick = event => {
        setCount(c => c + 1);
        if (!password) { setError(true) }
        else {
            passes[icon.id].toLowerCase() === password.toLowerCase() ? passCheck(true) : setError(true);
        }
        event.preventDefault();
    }

    return (
        <Draggable handle=".hak-modal-header-label-secondlevel" bounds={"#folder-bounds"}>
            <div className={"hak-modal-password"}>
                <Header icon={{ label: "PASSWORD REQUIRED!" }} close={close} secondLevel={true}/>
                <div className="row center">
                    <form className="hak-form">
                        {error && <div className="error-message center">Invalid Response</div>}
                        {icon.lock.type === "IT" && count >= error_message_max && <div className={"center"}>Hint: fill in the blank spaces</div>}
                        <span className="center" style={{ "marginBottom": "15px" }}>
                            {icon.lock.type === "IT" ? <PasswrodIT onChange={onChange} /> :
                                <FormControl
                                    type='password'
                                    name='password'
                                    defaultValue={password}
                                    onChange={event => onChange(event.target.value)}
                                    autoFocus
                                />}
                        </span>
                        <span className="center">
                            <Button type="submit" onClick={btnClick} className="btn btn-dark">ENTER</Button>
                        </span>
                    </form>
                </div>
            </div>
        </Draggable>
    )
}

export default Password;