import React from 'react';
import "./css/Home.css"
import { assets } from "./assets/assets.config"

const { baseURL, home: {
    HomeSVG
} } = assets;

export default function Home(props) {
    return (<div className="home-content d-flex align-items-center justify-content-center" >
        <img alt={"HomeSVG"} src={baseURL + HomeSVG} className="home-content-svg" />
    </div>);
}