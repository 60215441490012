import React, { useState, createRef, useEffect } from 'react'
import { FormControl } from 'react-bootstrap';
import './_shared/viewer.css';

const chars_length = 5;

const PasswordBoxes = ({ onChange }) => {

    const [elRefs, setElRefs] = useState([]);
    useEffect(() => {
        setElRefs(elRefs => (
            Array(chars_length).fill().map((_, i) => elRefs[i] || createRef())
        ));
    }, []);

    const boxes = new Array(chars_length);
    const [password, setPassword] = useState(Array(chars_length).fill(''));
    const onBoxInput = (val, index) => {
        let new_val = password;
        new_val[index] = val;
        setPassword(new_val);
        onChange(new_val.join(''))
        if (index + 1 < chars_length) elRefs[index%chars_length + 1].current.focus();
    };

    for (let i = 0; i < chars_length; i++) {
        (() => {
            let id = "pw_box_" + i
            boxes.push(
                <FormControl
                    type='password'
                    className={"hak-it-password-inputBox"}
                    key={id}
                    id={id}
                    autoFocus={(i === 0)}
                    maxLength={1}
                    defaultValue={password[i]}
                    onChange={(e) => onBoxInput(e.target.value, i)}
                    ref={elRefs[i]}
                    tabIndex={i}
                />
            );
        })();
    }
    return (<>{boxes}</>);
}

const PasswordIt = ({ onChange }) => {
    return (
        <div className="hak-it-password-container">
            <PasswordBoxes onChange={onChange} />
        </div>
    );
}

export default PasswordIt;