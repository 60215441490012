import React, { useState, createRef } from 'react';
import AudioPlayer from 'react-h5-audio-player'; 

import "./css/SoundsOfAshburne.css"

import { assets } from "./assets/assets.config"

const { baseURL, soundsOfAshburne: {
    SoundsYellowBarPNG,
    SoundsPhotoJPG,
    SoundsOfAshburneMP3,
    SoundsPlayButtonSVG,
    SoundsPauseButtonSVG,
    SoundsTitleSVG,
    FileNameBarSVG
} } = assets;

export default function SoundsOfAshburne(props) {
    const [audioPlaying, setAudioPlaying] = useState(false);

    const player = createRef();

    const playAudio = () => {
        setAudioPlaying(true);
        //document.getElementById("SoundsOfAshburne").play()
        player.current.audio.current.play();
    }

    const pauseAudio = () => {
        setAudioPlaying(false);
        //document.getElementById("SoundsOfAshburne").pause()
        player.current.audio.current.pause();
    }

    return (<div className="sounds-content d-flex align-items-center flex-column justify-content-center" style={{ backgroundImage: `url(${baseURL + SoundsYellowBarPNG})` }}>
        <div className={"d-flex align-items-center flex-column w-100 sounds-title-svg"}>
            <div className={"col-10 col-md-6"}>
                <img src={baseURL + SoundsTitleSVG} alt={"SoundsTitleSVG"} className={"d-flex"} />
            </div>
        </div>
        <div className={"d-flex align-items-center flex-column w-100 flex-wrap"}>
            <div className={"col-10 col-md-6"}>
                Learning to broadcast, report news, and DJ on the Camp Radio is one of our campers’ favorite activities here at Ashburne! This year, Counselor Ramil went the extra mile with audio editing to give families at home a taste of what campers experience during their time away.
            </div>
        </div>

        <div className={"d-flex align-items-center flex-column w-100 sounds-container"}>
            <div className={"col-12 col-md-6 align-items-center d-flex flex-column mt-5 sound-photo-container"}>
                <img alt={"SoundsPhotoJPG"} src={baseURL + SoundsPhotoJPG} className={"soundsphoto"} />
            </div>
            <div className={"audio-controls-container  d-none d-sm-flex flex-column justify-content-end align-items-start w-50 h-100"}>
                <div className={"audio-controls text-white h5 d-none d-sm-flex flex-row align-items-center"} style={{ backgroundImage: `url(${baseURL + FileNameBarSVG})` }}>
                    {audioPlaying ?
                        <img alt={"SoundsPauseButtonSVG"} src={baseURL + SoundsPauseButtonSVG} className={"d-flex p-2 soundsplaybutton pointer"} onClick={pauseAudio} />
                        :
                        <img alt={"SoundsPlayButtonSVG"} src={baseURL + SoundsPlayButtonSVG} className={"d-flex p-2 soundsplaybutton pointer"} onClick={playAudio} />}
                    <span className={"w-100 my-2"}>Sounds of Ashburne</span>
                </div>
            </div>
            <div className={"audio-controls text-white h5 d-flex d-sm-none flex-row  align-items-center"} style={{ backgroundImage: `url(${baseURL + FileNameBarSVG})` }}>
                {audioPlaying ?
                    <img alt={"SoundsPauseButtonSVG"} src={baseURL + SoundsPauseButtonSVG} className={"d-flex p-2 soundsplaybutton pointer"} onClick={pauseAudio} />
                    :
                    <img alt={"SoundsPlayButtonSVG"} src={baseURL + SoundsPlayButtonSVG} className={"d-flex p-2 soundsplaybutton pointer"} onClick={playAudio} />}
                <span className={"w-100 my-2"}>Sounds of Ashburne</span>
            </div>
            <div className={"bg-transparent soa-container"} id={"soa-container"}>
                {/* <audio controls={false} id={"SoundsOfAshburne"}>
                    <source src={baseURL + SoundsOfAshburneMP3} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio> */}
                <AudioPlayer ref={player} src={baseURL + SoundsOfAshburneMP3} showSkipControls={false} showJumpControls={false} showDownloadProgress={false} showFilledProgress={true} />
            </div>
        </div>
    </div>);
}