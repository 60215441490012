import React from 'react';


import "./css/History.css"

import { assets } from "./assets/assets.config"

const { baseURL, history: {
    HistoryYellowBarPNG,
    HistoryAdrienneAndJemmaJPG,
    HistoryTitleSVG
} } = assets;



export default function History(props) {
    return (<div className="history-content d-flex align-items-center flex-column justify-content-center" style={{ backgroundImage: `url(${baseURL + HistoryYellowBarPNG})` }}>
        <div className={"d-flex align-items-center flex-column w-100 history-title-svg"}>
            <div className={"col-10 col-md-6"}>
                <img alt={"HistoryTitleSVG"} src={baseURL + HistoryTitleSVG} className={"d-flex"} />
            </div>
        </div>
        <div className={"d-flex align-items-center flex-column w-100 flex-wrap"}>
            <div className={"col-10 col-md-6"}>
                Camp Ashburne is located amidst the splendor of the Adirondacks, on a site rich in history. This land was home to the Mohawk and Oneida tribes; later, it was the location of a pivotal battle of the American Revolution. The site became part of a large estate owned by the famous Van Rensselaer family. Nearby, they built a small village that grew into Hartskill Township.
                <br />
                <br />
                As Hartskill grew, Lake Ashburne served as a popular recreation spot for locals and tourists. By the late eighteenth century, it rivaled Lake George as one of the most popular destinations in all of upstate New York. Theodore Roosevelt spent several summers hunting in the nearby woods and as president attempted to incorporate Ashburne into a national park. After World War II, Camp Ashburne transformed itself into a popular summer destination on the Catskill circuit.
                <br />
                <br />
                The Cohen family purchased the campground with a dream of transforming it into a premier summer destination, a great place for children to learn about the outdoors and make memories that will last a lifetime. They continue that dream today!
            </div>
        </div>

        <div className={"d-flex align-items-center flex-column w-100 flex-wrap"}>
            <div className={"col-12 col-md-6 align-items-center d-flex flex-column my-5"}>
                <img alt={"HistoryAdrienneAndJemmaJPG"} src={baseURL + HistoryAdrienneAndJemmaJPG} className={"historyadrienneandjemma"} />
            </div>
        </div>
    </div>);
}